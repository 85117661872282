<template>
  <div>
    <el-card class="font-14">
      <div class="flex flex-column gap-20">
        <div class="flex flex-align-center flex-wrap gap-10">
          <div class="flex flex-align-center">
            <div class="color-606266 font-bold">关键字：</div>
            <el-input style="width:250px" placeholder="公司名称" v-model="keywords"></el-input>
          </div>
          <el-button type="primary" @click="query">查询</el-button>
        </div>
      </div>
      <el-table :data="tablelist" v-loading="tableloading" class="margin-top-20" tooltip-effect="dark popper-max500">
        <el-table-column prop="" label="客户编码" fixed width="120">
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis-two" @click="toDetails(scope.row)">
              {{scope.row.CRMCustomerNo}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="公司名称" fixed width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis" @click="toDetails(scope.row)">
              {{scope.row.CompanyName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="TypeName" label="客户类型" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.TypeName||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CRMCustomerLevelValue" label="客户级别" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.CRMCustomerLevelValue||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="FromName" label="客户来源" width="120">
          <template slot-scope="scope">
            <div>
              {{scope.row.FromName||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="销售区域" width="150">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.SellCity">
                {{scope.row.SellProvince}}/{{scope.row.SellCity}}
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="商机" width="120">
          <template slot-scope="scope">
            <div>
              <div class="tap-409eff ellipsis-two" @click="toDetails(scope.row,'1')" v-if="scope.row.BusinessOpportunityCount">
                {{scope.row.BusinessOpportunityCount}}
              </div>
              <div v-else>--</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="负责人" width="120">
          <template slot-scope="scope">
            <div>
              <div>{{scope.row.EmployeeName||'--'}}</div>
              <div>{{scope.row.EmployeePhone||'--'}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="源线索" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tap-409eff ellipsis" v-if="scope.row.ClueCompanyName" @click="toclueDataDetails(scope.row)">
              {{scope.row.ClueCompanyName}}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="作废原因" width="150">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.CancelReasonTypeValue||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="作废备注" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.CancelRemark||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="作废时间" width="150">
          <template slot-scope="scope">
            <div class="ellipsis">
              {{scope.row.CancelTime||'--'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="100px">
          <template slot-scope="scope">
            <div class="flex flex-align-center flex-wrap gap-10">
              <div>
                <el-button type="text" @click="toDetails(scope.row)">详情</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import {
  crmCustomercancellist,
} from "@/api/sv3.0.0"
import buttonPermissions from '@/components/buttonPermissions';
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      keywords:'',
      tablelist:[{}],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    toclueDataDetails(row){
      this.$router.push({
        name: 'clueDataDetails',
        query: {
          id:row.CRMClueId
        }
      })
    },
    toDetails(row,tab){
      this.$router.push({
        path:'/Customer/SellCustomerDetail',
        query:{
          Id:row.Id,
          tab:tab
        }
      })
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await crmCustomercancellist({
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.pagesize = e
      this.currentPage = 1
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },  
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
  }
}
</script>

<style lang='less' scoped>
.tap-409eff{
  color: #409eff;
  cursor: pointer;
}
/deep/tr{
  background-color: #f8f8f9
}
</style>